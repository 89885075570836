<template>
  <div>
    <div class="form-info">
      <div class="top">
        <div class="top1">
          1. 选择户籍性质
        </div>
        <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
        <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
        <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
        <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
        <div class="top1">
          2. 进行自主登记
        </div>
      </div>
      <van-form @submit="selectRecord" ref="form" :scroll-to-error="true">
        <div class="form">
          <div class="title">
            <div>扫描身份证件可自动生成个人信息</div>
            <van-uploader :after-read="cardIdIdentified">
              <img :src="require('@/assets/img/scan.png')" alt="" class="scan">
            </van-uploader>
          </div>
          <div class="headImgBtn">
            <van-uploader v-if="fieldList.indexOf('headImg') > -1" :after-read="headImgButton">
              <van-cell title="上传照片">
                <template>
                  <van-image v-if="show.headImg" class="headImg" fit="cover" :src="show.headImg" />
                  <div v-else style="color: #1677FF">点击上传</div>
                </template>
              </van-cell>
            </van-uploader>
          </div>
          <van-field v-if="fieldList.indexOf('idNumberType') > -1" name="证件类型" label="证件类型" v-model="show.idNumberTypeStr" placeholder="点击选择证件类型"
                     readonly clickable is-link @click="control.idNumberType = true"
                     required :rules="[{ required: true, message: '请选择证件类型' }]" />
          <van-popup v-model="control.idNumberType" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="idNumberTypeList" @confirm="idNumberType"
                        @cancel="control.idNumberType = false" />
          </van-popup>

          <van-field v-if="fieldList.indexOf('idNumber') > -1 && dataForm.idNumberType != 7" name="证件号" label="证件号" v-model="dataForm.idNumber" :placeholder="dataForm.idNumberType == 1 ? '请输入有效证件号' : '请输入证件号'"
                     clearable clear-trigger="always" @blur="getInfoByIdNumber"
                     required :rules="dataForm.idNumberType == 1 ? [{ validator: IdentityCodeValid, message: '请输入有效证件号' }] : [{required: true,message: '请输入证件号'}]" />

          <van-field v-if="fieldList.indexOf('name') > -1" name="姓名" label="姓名" v-model="dataForm.userName" placeholder="请输入姓名"
                     clearable clear-trigger="always"
                     required :rules="[{ required: true, message: '请输入姓名' }]" />

          <van-field v-if="fieldList.indexOf('sex') > -1" name="性别" label="性别" v-model="show.sex" placeholder="请选择性别"
                     readonly clickable is-link @click="control.sex = true"
                     required :rules="[{ required: true, message: '请选择性别' }]" />
          <van-popup v-model="control.sex" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="[{ label: '男', value: 1 }, { label: '女', value: 2 }]"
                        @confirm="sexConfirm" @cancel="control.sex = false" />
          </van-popup>

          <van-field v-if="fieldList.indexOf('mobile') > -1" name="手机号码" label="手机号码" v-model="dataForm.mobile" placeholder="请输入手机号码"
                     clearable clear-trigger="always"
                     required :rules="[{ validator: isMobile, message: '请输入正确的手机号码' }]" />

          <van-field v-if="fieldList.indexOf('nation') > -1" name="国籍" label="国籍" v-model="show.nationalityStr" placeholder="请选择国籍"
                     readonly clickable is-link @click="$router.push('./nationality')"
                     required :rules="[{ required: true, message: '请选择国籍' }]" />

          <van-field v-if="fieldList.indexOf('nationality') > -1" name="民族" label="民族" v-model="show.nationStr" placeholder="请选择民族"
                     readonly clickable is-link @click="$router.push('./nation')"
                     required :rules="[{ required: true, message: '请选择民族' }]" />

          <van-field v-if="fieldList.indexOf('nativePlace') > -1" name="籍贯" label="籍贯" v-model="show.nativePlace" placeholder="请选择籍贯"
            :type="show.nativePlace ? show.nativePlace.length > 10 ? 'textarea': 'text' : 'text'" :autosize="show.nativePlace && show.nativePlace.length > 10"
            readonly clickable is-link @click="control.nativePlace = true"/>
          <van-popup v-model="control.nativePlace" position="bottom">
            <van-cascader title="请选择" :options="areaList" :field-names="fieldNames" v-model="dataForm.nativePlace"
                          @change="nativePlaceConfirm"  @finish="nativePlaceFinish" @close="control.nativePlace = false"/>
          </van-popup>
          <van-field v-if="fieldList.indexOf('birthday') > -1" name="出生日期" label="出生日期" v-model="dataForm.birthday" placeholder="请选择出生日期"
                     readonly clickable is-link @click="control.birthday = true"
                     required :rules="[{ required: true, message: '请选择出生日期' }]" />
          <van-popup v-model="control.birthday" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择出生日期"
              :min-date="new Date(1945, 0, 1)"
              :max-date="new Date()"
              @cancel="control.birthday = false"
              @confirm="birthdayCalendar"
            >
            </van-datetime-picker>
          </van-popup>
          <!-- <van-calendar v-model="control.birthday" :show-confirm="false" color="#1989fa"
                        :default-date="new Date(dataForm.birthday)" :min-date="new Date(1945, 0, 1)" :max-date="new Date()"
                        @confirm="birthdayCalendar" /> -->


          <van-field v-if="fieldList.indexOf('company') > -1" name="工作单位" label="工作单位" v-model="dataForm.company" placeholder="请输入工作单位"
                     clearable clear-trigger="always" type="textarea" autosize :class="{'isEmpty': !dataForm.company}" />

          <van-field v-if="fieldList.indexOf('emContact') > -1" name="紧急联系人" label="紧急联系人" v-model="dataForm.emContact" placeholder="请输入紧急联系人"
                     clearable clear-trigger="always" />

          <van-field v-if="fieldList.indexOf('emMobile') > -1" name="联系人手机号" label="联系人手机号" v-model="dataForm.emMobile" placeholder="请输入联系人手机号"
                     clearable clear-trigger="always" />

        </div>

        <div class="form" v-if="fieldList.indexOf('regAddress') > -1 || fieldList.indexOf('house') > -1 || isInCommunity || fieldList.indexOf('nowAddress') > -1 || !!dataForm.houseName">
          <div class="title">居住信息</div>
          <van-field v-if="fieldList.indexOf('regAddress') > -1" name="户籍地址" label="户籍地址" v-model="dataForm.regAddress" placeholder="请输入户籍地址"
                     clearable clear-trigger="always" type="textarea" autosize :class="{'isEmpty': !dataForm.regAddress}" required  :rules=" [{ required: true, message: '请填写户籍地址' }]"/>
          <van-field v-if="!isScan && (fieldList.indexOf('house') > -1 || isInCommunity)" name="居住房屋" label="居住房屋" v-model="dataForm.houseName"
                     placeholder="请选择居住房屋" type="textarea" autosize :class="{'isEmpty': !dataForm.houseName}" readonly :clickable="false" is-link @click="clickHouseSelect"
                     :required="isInCommunity || $userType == 2" :rules="(isInCommunity || $userType == 2) ? [{ required: true, message: '请选择居住房屋' }] : []" />
          <!-- <van-field v-else-if="!!dataForm.houseName" name="居住房屋" label="居住房屋" v-model="dataForm.houseName"
                     type="textarea" autosize readonly :clickable="false" /> -->
          <house-select v-if="control.houseSelect" ref="houseSelect" @houseConfirm="houseConfirm"></house-select>
          <van-popup v-model="control.myHouseSelect" position="bottom">
            <van-picker show-toolbar value-key="fullName" :columns="houseList"
                        @confirm="myHouseConfirm($event)" @cancel="control.myHouseSelect = false" />
          </van-popup>


          <van-field v-if="fieldList.indexOf('house') > -1 || isInCommunity || !!dataForm.houseName" name="与房主关系" label="与房主关系" v-model="show.relationship"
                     placeholder="请选择与房主关系" readonly :clickable="$isZuke || $userType != 2" is-link @click="control.relationship = (!$isZuke && $userType != 2)"
                     :required="isInCommunity || !!dataForm.houseName || isScan" :rules="(isInCommunity || !!dataForm.houseName || isScan) ? [{ required: true, message: '请选择与房主关系' }] : []" />
          <van-popup v-model="control.relationship" position="bottom">
            <van-picker show-toolbar value-key="label" :columns="relationshipList"
                        @confirm="relationshipConfirm($event)" @cancel="control.relationship = false" />
          </van-popup>

          <van-field v-if="dataForm.relationship == 10 || show.relationship == '租客'" name="隔间房号" label="隔间房号" v-model="dataForm.room" placeholder="示例：1号房"
                     clearable clear-trigger="always" :required="DZ==1"  :rules=" [{ required:DZ==1, message: '请填写隔间房号' }]"/>

          <van-field v-if="fieldList.indexOf('nowAddress') > -1" name="现居住地" label="现居住地" v-model="dataForm.nowAddress" placeholder="请输入现居住地"
                     clearable clear-trigger="always" type="textarea" autosize :class="{'isEmpty': !dataForm.nowAddress}" required  :rules=" [{ required: true, message: '请填写现居住地' }]"/>

        </div>

        <div class="form" v-if="dataForm.relationship == 10 || $userType == 2">
          <div class="title">租赁信息</div>
          <van-field name="入住时间" label="入住时间" v-model="dataForm.moveInDate"
                     placeholder="请选择入住时间" readonly clickable is-link @click="control.moveInDate = true" />
          <van-popup v-model="control.moveInDate" position="bottom">
            <van-datetime-picker
                type="date"
                title="选择年月日"
                :min-date="new Date(2000, 0, 1)"
                :max-date="new Date(2030, 11, 31)"
                @confirm="moveInDateConfim"
                @cancel="control.moveInDate = false"
            />
          </van-popup>

          <van-field name="租赁开始时间" label="租赁开始时间" v-model="dataForm.startDate"
                     placeholder="请选择租赁开始时间" readonly clickable is-link @click="control.startDate = true"
                     required :rules="[{ required: true, message: '请选择租赁开始时间' }]" />
          <van-popup v-model="control.startDate" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="new Date(2000, 0, 1)"
              :max-date="new Date(2030, 11, 31)"
              @confirm="restStartTimeConfim"
              @cancel="control.startDate = false"
            />
          </van-popup>

          <van-field name="租赁结束时间" label="租赁结束时间" v-model="dataForm.endDate"
                     placeholder="请选择租赁结束时间" readonly clickable is-link @click="control.endDate = true"
                     required :rules="[{ required: true, message: '请选择租赁结束时间' }]" />
          <van-popup v-model="control.endDate" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="new Date(2030, 11, 31)"
              @confirm="restEndTimeConfim"
              @cancel="control.endDate = false"
            />
          </van-popup>

        </div>

        <div class="form" v-if="fieldList.indexOf('label') > -1">
          <div class="title">标签信息</div>
          <van-checkbox-group v-model="dataForm.labels" direction="horizontal" style="padding-bottom: 10px; padding-left: 14px">
            <van-checkbox name="1" style="font-size: 14px">党员</van-checkbox>
            <van-checkbox name="6" style="font-size: 14px">退役军人</van-checkbox>
            <van-checkbox name="8" style="font-size: 14px">残疾人</van-checkbox>
          </van-checkbox-group>
        </div>
      </van-form>

    </div>

    <div class="btn" @click="selectRecord">提交</div>
  </div>
</template>

<script>
import { getImageStream } from "@/utils/index";
import { upload, getVirtualDict, getAreaList } from "@/utils/common"
import { formatterDate, getSexFromIdCard, getBirthday} from "@/utils/utils";
import data from "@/utils/data"
import { IdentityCodeValid, isMobile } from "@/utils/validate";
import HouseSelect from "@/components/houseSelect/index.vue";
import {mapState} from "vuex";
export default {
  name: 'newRegister-form',
  components: {
    HouseSelect
  },
  data () {
    return {
      isInCommunity: false,
      isScan: false,
      isRenter: false,
      fieldList: [],
      dataForm: {
        id: '',
        orgId: '',
        userName: '',
        name: '',
        sex: '',
        nationality: '59',
        idNumber: '',
        idNumberType: 1,
        mobile: '',
        nation: '2',
        birthday: '',
        company: '',
        nativePlace: '',
        regAddress: '',
        nowAddress: '',
        emContact: '',
        emMobile: '',
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        relationship: '',
        registryType: '',
        subarea: '',
        houseId: '',
        houseName: '',
        room: '',
        startDate: '',
        endDate: '',
        labels: [],
        rooms: '',
        moveInDate: ''
      },
      show: {
        community: '',
        idNumberTypeStr: "身份证",
        sex: '',
        nativePlace: '',
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: '',
        registryTypeStr: "",
        relationship: '',
        religious: '',
        subarea: '',
        houseName: ''
      },
      //duzhuang
      DZ:0,//0不是
      houseId:'',
      control: {
        idNumberType: false,
        sex: false,
        nativePlace: false,
        birthday: false,
        houseSelect: false,
        myHouseSelect: false,
        relationship: false,
        startDate: false,
        endDate: false,
        moveInDate: false,
        isOpen: false
      },
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      idNumberTypeList: [],
      areaList: [],
      relationshipList: [],
      houseList: [],
      houseChangeType: '',
      isList: '', // 是否是家人、房屋下的居民、租客列表进入 存在是，不存在否
      minDate: new Date(2000,0,1)
    }
  },
  created() {
    let that = this
    this.areaList = data
    console.log(this.$route.query)
    this.isList = this.$route.query.isList
    this.dataForm.registryType = this.$route.query.registryType

    this.isInCommunity = this.$route.query.isInCommunity == 1 ? true : false
    if(!this.isList) {
      if (this.$userName) this.dataForm.userName = this.$userName
      if (this.$mobile) this.dataForm.mobile = this.$mobile
      if (this.$idNumber) {
        this.dataForm.idNumber = this.$idNumber
        this.dataForm.sex = getSexFromIdCard(this.dataForm.idNumber)
        this.show.sex = getSexFromIdCard(this.dataForm.idNumber) == 1 ? '男' : getSexFromIdCard(this.dataForm.idNumber) == 2 ? '女' : '未知'
        this.dataForm.birthday = getBirthday(this.dataForm.idNumber)
        // this.getInfoByIdNumber()
      }
    }
    if (this.$userType == 2) {
      document.title = '租客自主登记'
      this.show.relationship = '租客'
    }
    if(this.$isZuke) {
      this.show.relationship = '租客'
      this.dataForm.relationship = 10
    }
    getVirtualDict("idNumberType", function (virtualDictList) {
      that.idNumberTypeList = virtualDictList
    })
    // getAreaList(1, 0, (res) => {
    //   that.areaList = res
    // })
    this.$http({
      url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({ data }) => {
      if (data.code == 0) {
        data.relationships.map((r) => {
          r.value = r.value + ""
        })
        this.relationshipList = data.relationships
      }
    })
    this.getFormInfo()
    if(this.$route.query.title) {
      document.title = this.$route.query.title
    }
    if(this.$route.query.houseId) {
      this.dataForm.houseId = this.$route.query.houseId
      this.dataForm.subarea = this.$route.query.subarea
      this.dataForm.houseName = this.$route.query.houseName
    }
    // PC端的登记码会没有houseId,就需要选择房屋
    if((this.$isTenant == 1 || this.$isTenant == 0) && this.$houseId) {
      this.isScan = true

    }else {
      this.isScan = false
    }
    if(this.$route.query.myHouse) {
      if(document.title == '添加家人' || document.title == '租客登记') {
        this.houseChangeType = 1
        this.getMyHouse()
      }else {
        this.houseChangeType = 0
      }
    }
    if(this.$purePhoneNumber) {
      this.dataForm.mobile = this.$purePhoneNumber
    }
    this.initNation()
    this.gethourse()
  },
  activated () {
    let that = this
    // getVirtualDict("idNumberType", function (virtualDictList) {
    //   that.idNumberTypeList = virtualDictList
    // })
    // getAreaList(1, 0, (res) => {
    //   that.areaList = res
    // })
    this.dataForm.registryType = this.$route.query.registryType
    if(this.$route.query.title) {
      document.title = this.$route.query.title
    }
    if(this.$route.query.houseId) {
      this.dataForm.houseId = this.$route.query.houseId
      this.dataForm.subarea = this.$route.query.subarea
      this.dataForm.houseName = this.$route.query.houseName
    }
    if((this.$isTenant == 1 || this.$isTenant == 0) && this.$houseId) {
      this.isScan = true
    }else {
      this.isScan = false
    }
    if(this.$route.query.myHouse) {
      if(document.title == '添加家人' || document.title == '租客登记') {
        this.houseChangeType = 1
        this.getMyHouse()
      }else {
        this.houseChangeType = 0
      }
    }
    if (this.nationality != 0 && typeof this.nationality == 'object') {
      this.show.nationalityStr = this.nationality.label
      this.dataForm['nationality'] = this.nationality.value
    }
    if (this.nation != 0 && typeof this.nation == 'object') {
      this.show.nationStr = this.nation.label
      this.dataForm['nation'] = this.nation.value
    }
    this.dataForm.nowAddress = this.$houseName
  },

  computed: { ...mapState(['nationality', 'nation']) },
  methods: {
    //获取是否是杜庄
    gethourse(){
      if(this.$houseId||this.dataForm.houseId)  {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/info/infoByHouseId'),
          method: 'GET',
          params: this.$http.adornParams({
            id: this.$houseId||this.dataForm.houseId
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log(123456)
            console.log(data.info)
       if(data.info.divideUnit == 0 && data.info.divideHouse ==0){
         this.DZ=1
       }
       else{
         this.DZ=0
       }
          }
        })
      }
    },
    // 初始化国籍和民族
    initNation() {
      this.DZ=0;
      this.$store.commit('setNation', 2)
      this.$store.commit('setNationality', 59)
    },
    getMyHouse() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'POST',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          userId: this.$userId,
          orgId: this.$orgId,
          loginUserId: this.$userId,
          loginUserOrgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.houseList = data.page.list
        }
      })
    },
    myHouseConfirm(e) {
      this.dataForm.houseId = e.id
      this.dataForm.subarea = e.subarea
      this.dataForm.houseName = e.fullName
      this.control.myHouseSelect = false
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          wxAppId: 'wxfa4ce77dc91e7327'
        },
        data: formdata
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.userName = data.message.name
          this.dataForm.idNumberType = 1
          this.dataForm.idNumber = data.message.id
          this.dataForm.regAddress = data.message.addr
          this.dataForm.birthday = data.message.birth
          this.dataForm.sex = data.message.gender == "男" ? 1 : 2
          this.show.sex = data.message.gender
          this.dataForm.nation = data.message.nation
          this.show.nationStr = data.message.nationality
          this.show.idNumberTypeStr = '身份证'
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getFormInfo () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/autonomousregistrationorgconfig/notLogin/list`),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.fieldList = data.list.fieldList.map(item => {
            return item.name
          })
        }
      })
    },
    IdentityCodeValid(val) {
      return IdentityCodeValid(val)
    },
    isMobile (val) {
      return isMobile(val)
    },
    headImgButton(e) {
      let path = "files/userHeadImg/temporary"
      let that = this
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      upload(e.file, path, null, function (res) {
        that.show.headImg = getImageStream(res.fileMessage.relativePath)
        that.dataForm.temporaryHeadImg = res.fileMessage.relativePath
        that.dataForm.temporaryHeadImgSuffix = res.fileMessage.suffix
        that.$toast.clear()
      })
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    getInfoByIdNumber() {
      if(!this.dataForm.idNumber && this.dataForm.idNumberType == 1) return
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/userByIdNumberNotLogin`),
        method: 'get',
        params: this.$http.adornParams({
          idNumber: this.dataForm.idNumber
        }, false)
      }).then(({ data }) => {
        if (data.code == 0) {
          if (data.userInfoList && data.userInfoList.length > 0) {
            this.dataFormInfo(data.userInfoList[0])
          }else {
            this.dataForm.id = 0
            this.dataForm.userName = ""
            this.dataForm.mobile =  ""
            this.dataForm.sex = ""
            // this.dataForm.nationality = "59"
            // this.dataForm.nation = "2"
            this.dataForm.birthday = ""
            this.dataForm.regAddress = ""
            this.dataForm.nowAddress = ""
            this.dataForm.nativePlace = ""
            this.show.nativePlace = ""
            this.dataForm.headImg = ""
            this.dataForm.company = ""
            this.show.sex = ""
            // this.show.nationalityStr = "中国"
            // this.show.nationStr = "汉族"
            // this.show.idNumberTypeStr = "身份证"
            // this.dataForm.idNumberType = "1"
            this.show.headImg = ""
            this.dataForm.emMobile = ""
            this.dataForm.emContact = ""
            if (this.dataForm.idNumberType == 1) {
              this.dataForm.sex = getSexFromIdCard(this.dataForm.idNumber)
              this.show.sex = getSexFromIdCard(this.dataForm.idNumber) == 1 ? '男' : getSexFromIdCard(this.dataForm.idNumber) == 2 ? '女' : '未知'
              this.dataForm.birthday = getBirthday(this.dataForm.idNumber)
            }
          }
        }
      })
    },
    dataFormInfo(info) {
      // 用户基本信息
      this.dataForm.id = info.id || 0
      this.dataForm.userName = info.name || ""
      this.dataForm.mobile = info.mobile || ""
      this.dataForm.sex = info.sex || ""
      this.dataForm.nationality = info.nationality || ""
      this.dataForm.nation = info.nation || ""
      this.dataForm.idNumber = info.idNumber || ""
      this.dataForm.birthday = info.birthday || ""
      this.dataForm.regAddress = info.regAddress || ""
      this.dataForm.nowAddress = info.nowAddress || ""
      this.dataForm.headImg = info.headImg || ""
      this.dataForm.company = info.company || ""
      this.show.sex = info.sex == 1 ? '男' : '女'
      this.show.nationalityStr = info.nationalityStr || "未知"
      this.show.nationStr = info.nationStr || "未知"
      this.show.idNumberTypeStr = info.idNumberTypeStr || "身份证"
      this.dataForm.idNumberType = info.idNumberType || "1"
      if (null != info.headImg) {
        this.show.headImg = info.headImg ? getImageStream(info.headImg) : getImageStream("files/wx/images/content/headImg.png")
      }
      // this.dataForm.registryType = info.registryType + '' || ""
      this.dataForm.emMobile = info.emMobile || ""
      this.dataForm.emContact = info.emContact || ""
      // this.show.registryTypeStr = info.registryTypeStr || ""
    },
    nativePlaceConfirm (e) {
      let val = e.selectedOptions[e.selectedOptions.length - 1]
      if (val.depth < 3) {
        getAreaList(val.value, val.depth, (list) => {
          e.selectedOptions.forEach(item => {
            if (item.value == val.value) {
              item.children = list.length > 0 ? list.map(item => {
                return {
                  ...item,
                  children: item.depth == 3 ? null : []
                }
              }) : null
            }
          })
        })
      }
    },
    nativePlaceFinish({ selectedOptions }) {
      if (selectedOptions.length == 3) {
        this.dataForm.nativePlace = selectedOptions.map(item => item.label).join('')
        this.show.nativePlace = selectedOptions.map(item => item.label).join('')
        this.control.nativePlace = false
      }
    },
    sexConfirm(value) {
      this.dataForm.sex = value.value
      this.show.sex = value.label
      this.control.sex = false
    },
    birthdayCalendar(e) {
      let date = formatterDate(e)
      this.control.birthday = false
      this.dataForm.birthday = date
    },
    clickHouseSelect () {
      if(!this.dataForm.houseName || !this.isList) {
        if(this.houseChangeType) {
          this.control.myHouseSelect = true
        }else {
          this.control.houseSelect = true
          this.$nextTick(() => {
            this.$refs.houseSelect.visible = true
          })
        }
      }
    },
    houseConfirm(houseId, houseNameList) {
      if (houseId) {
        this.dataForm.houseId = houseId
        this.dataForm.subarea = houseNameList[0].value
        this.dataForm.houseName = houseNameList.map(item => item.label).join('')
        this.control.houseSelect = false
        this.gethourse()

      }
    },
    relationshipConfirm(e) {
      this.dataForm.relationship = e.value
      this.show.relationship = e.label
      this.control.relationship = false
    },
    moveInDateConfim (value) {
      this.dataForm.moveInDate = formatterDate(value)
      this.control.moveInDate = false
    },
    restStartTimeConfim(value) {
      this.dataForm.startDate = formatterDate(value)
      let year = this.dataForm.startDate.slice(0,4)
      let mon = this.dataForm.startDate.slice(5,7)
      mon = mon > 9 ? mon - 1 : mon.slice(1) - 1
      let day = this.dataForm.startDate.slice(8,10)
      day = day > 9 ? day : day.slice(1)
      this.minDate = new Date(year,mon,day)
      this.control.startDate = false
    },
    restEndTimeConfim(value) {
      this.dataForm.endDate = formatterDate(value)
      if(this.dataForm.endDate === this.dataForm.startDate) {
        this.$toast.fail({
          message: '请勿选择相同时间',
          duration: 1500,
        })
        setTimeout(() => {
          this.dataForm.endDate = ''
        },1500)
      }
      this.control.endDate = false
    },
    // 根据返回是否弹出提醒
    selectRecord () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/selectRecordByUserId`),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          type: !this.isList ? 3 : 5
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          this.control.isOpen = data.map.open
          if(data.map.data == null) {
            this.submit()
          } else if (data.map.data && data.map.data) {
            this.$toast({
              message: `您于${data.data.createTime}已经提交新增申请！`,
              duration: 2000,
              forbidClick: true
            })
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    submit(e) {
      this.$refs.form.validate().then(() => {
        if(this.dataForm.relationship == 10 && this.dataForm.startDate >= this.dataForm.endDate) return this.$toast.fail('租赁开始时间不能大于或等于结束时间')
        this.$dialog.confirm({
          message: '确认提交？',
        }).then(() => {
          if (this.$userType != 2) {
            this.$http({
              url: this.$http.adornUrl(`/wxapp/user/ResInfo`),
              method: 'post',
              data: this.$http.adornData({
                'operatorUserId': this.$globalData.userInfo.userId,
                'registerType': 5, //登记类型 3-自主登记 5-新增家人
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.userName,
                'idNumber': this.dataForm.idNumber,
                'idNumberType': this.dataForm.idNumberType,
                'mobile': this.dataForm.mobile,
                'regAddress': this.dataForm.regAddress,
                'headImg': this.show.headImg,
                'registryType': this.dataForm.registryType,
                'subarea': this.dataForm.subarea,
                'nativePlace': this.dataForm.nativePlace,
                'temporaryHeadImg': this.dataForm.temporaryHeadImg,
                'temporaryHeadImgSuffix': this.dataForm.temporaryHeadImgSuffix,
                'sex': this.dataForm.sex,
                'nationality': this.dataForm.nationality,
                'nation': this.dataForm.nation,
                'birthday': this.dataForm.birthday,
                'nowAddress': this.dataForm.nowAddress,
                'company': this.dataForm.company,
                'houseId': this.dataForm.houseId || this.$houseId,
                'fullName': this.dataForm.houseName,
                'emMobile': this.dataForm.emMobile,
                'emContact': this.dataForm.emContact,
                'relationship': this.dataForm.relationship,// 与房主关系
                'userCode': this.$userCode,
                'isSimple': true,
                'source': this.$userId || 0,
                'rentStartDate': this.dataForm.startDate,
                'rentFinishDate': this.dataForm.endDate,
                'community': this.$orgId,
                'labels': this.dataForm.labels.toString(),
                'room': this.dataForm.room,
              })
            }).then(({ data }) => {
              if (data.code == 0) {
                this.$toast.success({
                  message: `${this.control.isOpen ? '居民信息已提交审核' : '操作成功'}`,
                  duration: 3000,
                  onOpened: () => {
                    setTimeout(() => {
                      if(this.$toSuccess) {
                        this.$router.push('/registerSuccess')
                        return
                      }
                      if(this.isList) {
                        this.$router.go(-2)
                      }else {
                        if (this.$agentType == 2) {
                          my.navigateTo({
                            url: '/pages/login/new-white?idNumber=' + this.dataForm.idNumber
                          })
                        } else {
                          wx.miniProgram.redirectTo({
                            url: '/pages/login/login'
                          })
                        }
                      }
                    }, 1500)
                  }
                })
              } else {
                this.$toast.fail(data.msg)
              }
            })
          } else {
            this.$http({
              url: this.$http.adornUrl(`/wxapp/user/jmgg/save`),
              method: 'POST',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.userName,
                'idNumber': this.dataForm.idNumber,
                'idNumberType': this.dataForm.idNumberType,
                'mobile': this.dataForm.mobile,
                'regAddress': this.dataForm.regAddress,
                'headImg': this.show.headImg,
                'registryType': this.dataForm.registryType,
                'nativePlace': this.dataForm.nativePlace,
                'orgId': this.$orgId,
                'temporaryHeadImg': this.dataForm.temporaryHeadImg,
                'temporaryHeadImgSuffix': this.dataForm.temporaryHeadImgSuffix,
                'sex': this.dataForm.sex,
                'nationality': this.dataForm.nationality,
                'nation': this.dataForm.nation,
                'birthday': this.dataForm.birthday,
                'nowAddress': this.dataForm.nowAddress,
                'company': this.dataForm.company,
                'emMobile': this.dataForm.emMobile,
                'emContact': this.dataForm.emContact,
                'source': this.$userId,
                'createUserId': this.$userId,
                'labels': this.dataForm.labels.toString(),
                'moveInDate': this.dataForm.moveInDate
              })
            }).then(({ data }) => {
              if (data.code == 0) {
                this.$http({
                  url: this.$http.adornUrl(`/wxapp/building/house/jmgg/res/save`),
                  method: 'POST',
                  data: this.$http.adornData({
                    subArea: this.dataForm.subarea,
                    houseId: this.dataForm.houseId || this.$houseId,
                    relationship: "10",
                    nowLive: "1",
                    moveOut: '0',
                    fullName: this.dataForm.houseName,
                    room: this.dataForm.room,
                    rentStartDate: this.dataForm.startDate,
                    rentFinishDate: this.dataForm.endDate,
                    createUserId: this.$userId,
                    userId: data.userId,
                    modelType: this.$globalData.userInfo.assistId ? 4 : this.dataForm.registryType ? this.$route.query.myHouse ? 2 : 1 : ''
                  })
                }).then(({ data }) => {
                  if (data.code === 0) {
                    this.$toast.success({
                      message: '保存成功',
                      duration: 3000,
                      onOpened: () => {
                        setTimeout(() => {
                          if(this.$toSuccess) {
                            this.$router.push('/registerSuccess')
                            return
                          }
                          if(this.isList) {
                            this.$router.go(-2)
                          }else {
                            if (this.$agentType == 2) {
                              my.navigateTo({
                                url: '/pages/login/new-white?idNumber=' + this.dataForm.idNumber
                              })
                            } else {
                              wx.miniProgram.redirectTo({
                                url: '/pages/login/login'
                              })
                            }
                          }

                        }, 1500)
                      }
                    })
                  }
                  if(data.code==500){
                    this.$toast.fail(data.msg)
                  }
                })
              } else {

                this.$toast.fail(data.msg)
              }
            })
          }
        })
      })
    }
  },

}
</script>

<style scoped>
.form-info {
  padding-bottom: 200px;
}
.top {
  padding: 24px 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top1 {
  width: 262px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-image: url("~@/assets/img/progress-active.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 26px;
  color: #4581F8;
}
.form {
  margin-top: 24px;
  background: #fff;
}
.title {
  line-height: 96px;
  color: #333;
  padding: 0 30px;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.scan {
  width: 40px;
  height: 40px;
}
.headImgBtn::v-deep .van-uploader,
.headImgBtn::v-deep .van-uploader__input-wrapper {
  width: 100%;
}
.headImg {
  width: 120px;
  height: 120px;
}
.headImg >>> .van-image__img {
  width: 120px;
  height: 120px;
}
.van-image__img {
  border-radius: 8px;
}
.van-cell {
  align-items: center;
}

::v-deep .van-field__control--left {
  text-align: left !important;
}
.btn {
  position: fixed;
  bottom: 40px;
  width: 690px;
  left: 30px;
  line-height: 88px;
  text-align: center;
  color: #fff;
  background: #4581F8;
  border-radius: 8px;
  font-size: 32px;
}
::v-deep .van-cell__title {
  font-size: 32px;
  color: #666;
}
::v-deep .van-cell__value {
  font-size: 32px;
}
::v-deep .isEmpty textarea {
  height: 50px !important;
}
</style>
